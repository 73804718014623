import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState
} from "react";
import { Data, Events, Overrides } from "@transformd-ltd/sdk";
import { HomeIcon } from "@heroicons/react/outline";
import { Helmet } from "react-helmet";
import { useQueryParam, NumberParam, StringParam } from "use-query-params";
import {
  Formatic,
  CustomCheckboxList,
  CustomRadioList,
  RelationshipCustom,
  CompanySearchAutocomplete
} from "../components";
import type { Verification } from "../types/Verification";

const formProps = {
  apiServerUrl: "https://api-staging.transformd.com",
  serverUrl: "https://api-staging.transformd.com/graphql",
  subscriptionServerUrl: "wss://api-staging.transformd.com/subscriptions"
};

export type EmitterData = {
  fieldID: string;
  newValue: string | string[];
  oldValue: string | string[];
};

export type FormData = {
  [key: string]: any;
  getEmitter: () => any;
};

const Heading = ({ page }) => {
  const refreshPage = () => {
    if (typeof window !== undefined) {
      window.location.reload();
    }
  };

  return (
    <div className="space-y-8 text-center">
      <Helmet title="APLYiD Onboarding - Real Estate Demo" defer={false} />
      {page === "6270a35f27809c00124ce1a3" && (
        <div className="h-10">
          <h2 className="text-xl leading-tight font-serif-finance text-finance-green-300 lg:text-3xl lg:leading-10">
            Let's get started
          </h2>
        </div>
      )}
      {page === "6278680464f3d400735474e2" && (
        <div className="h-10">
          <h2 className="text-xl leading-tight font-serif-finance text-finance-green-300 lg:text-3xl lg:leading-10">
            Let's get your data
          </h2>
        </div>
      )}
      {page === "627881f264f3d4006351689b" && (
        <div className="flex flex-col items-center justify-center space-y-4">
          <h1 className="text-3xl">Listing address</h1>
          <p className="text-xl font-light">
            Let us know a few details about you so we can be as helpful as
            possible.
          </p>
        </div>
      )}
      {page === "62819f9bca5e5100664c10cc" && (
        <div className="flex flex-col items-center justify-center space-y-4">
          <h1 className="text-3xl">Thank you!</h1>
          <p className="text-xl font-light">We have your details now.</p>
          <button
            type="button"
            className="inline-flex items-center px-6 py-3 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-full shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={refreshPage}
          >
            Start Over
          </button>
        </div>
      )}
      {/* {page} */}
    </div>
  );
};

const nonFields = ["heading", "richTextArea"];

const RealEstatePage: FunctionComponent = (): ReactElement => {
  const [submissionId, setSubmissionId] = useQueryParam(
    "submissionId",
    StringParam
  );
  const [page, setPage] = useState<string>("624ce8c22b1d8500091c18c7");
  const [name, setName] = useState<string>("");
  const [verificationResult, setVerificationResult] = useState<
    Verification | {}
  >({});
  const [hasValuables, setHasValuables] = useState<string>("NO");
  const [status, setStatus] = useState<string>("base");
  const [data] = useState<FormData>(new Data());

  const order = ({
    dataForm,
    submission,
    currentPage,
    visibleFields,
    event
  }) => {
    const { fields, sections, pages } = dataForm;

    const pagesMap = pages
      ?.map(paged =>
        paged?.sections?.map(sectionLol => {
          const newFields = sections
            ?.filter(section => section.id === sectionLol.id)
            ?.map(section => {
              const newSection = section.fields.map(fieldLol => {
                const newFields = fields
                  ?.filter(field => field.id === fieldLol.id)
                  ?.filter(field => !nonFields.includes(field.renderer))
                  ?.map(field => ({
                    fieldId: field.id,
                    value: submission?.fieldsById?.[field?.id]?.value ?? null,
                    errors: visibleFields?.[field?.id]?.errors
                      ? Object.keys(visibleFields?.[field?.id]?.errors)
                      : []
                  }));

                return newFields;
              });
              return newSection;
            })
            ?.flat(2);

          return {
            page: paged?.attributes?.label,
            id: paged?.id,
            currentPage: paged?.id === page,
            fields: newFields
          };
        })
      )
      ?.flat()
      ?.filter(paged => paged?.fields?.length)
      ?.filter(paged => paged?.currentPage)
      ?.shift();

    const hasValues = pagesMap?.fields?.some(field => field?.value);
    const hasErrors = pagesMap?.fields?.some(field => field?.errors?.length);

    // console.log({ pagesMap, hasValues, hasErrors });

    if (!hasValues && !hasErrors) {
      return "base";
    }

    if (hasValues && !hasErrors) {
      if (event === "valueChanged") {
        return "validating";
      }
      return "success";
    }

    if (hasErrors) {
      return "error";
    }

    return "base";
  };

  const getValues = (newData, event) => {
    if (
      newData?.formatic?.dataForm?.pages?.length &&
      newData?.formatic?.data?.submission &&
      newData?.formatic?.visibleFields
    ) {
      const newStatus = order({
        dataForm: newData.formatic.dataForm,
        submission: newData.formatic.data.submission,
        visibleFields: newData.formatic.visibleFields,
        currentPage: page,
        event
      });
      return setStatus(newStatus);
    }
    return setStatus("base");
  };

  const getVerificationData = renderData => {
    const verification: Verification | { type: null } =
      renderData?.formatic?.data?.submission?.fieldsById?.[
        "627853341fd4a100512c7334"
      ]?.value?.verification ?? {};

    // console.log(verification);

    if (verification && verification?.type) {
      setVerificationResult(verification);
    }
  };

  const inputChangeListener = () => {
    const emitter = data?.getEmitter();

    // Listen to page render events and set id of current page
    emitter.on(Events.PageRender, page => {
      const renderData = data?.store?.getState();
      getVerificationData(renderData);
      setPage(page.id);
      getValues(renderData, "pageRender");
    });

    // Listen to field change events and update state of some values (name,valuables)
    emitter.on(Events.ValueChanged, (emitterData: EmitterData) => {
      const valueChangedData = data?.store?.getState();
      //   console.log({
      //     fieldId: emitterData.fieldID,
      //     value: emitterData.newValue,
      //     valueChangedData
      //   });
      if (emitterData.fieldID === "624ce9f92b1d8500091c18d3") {
        setName(emitterData?.newValue?.toString());
      }
      if (emitterData.fieldID === "624e12d62b1d85001f01a916") {
        setHasValuables(emitterData?.newValue?.toString());
      }
      getValues(valueChangedData, "valueChanged");
    });

    emitter.on(Events.FieldValidated, () => {
      const validationData = data?.store?.getState();
      getValues(validationData, "fieldValidated");
    });

    emitter.on(Events.FieldError, () => {
      const errorData = data?.store?.getState();
      getValues(errorData, "fieldError");
    });
  };

  useEffect(() => {
    // console.log(page);
    inputChangeListener();
  }, [page]);

  return (
    <div>
      <div className="fixed top-0 left-0 right-0 z-10 flex items-center justify-between w-full h-16 p-4 space-x-6 text-white bg-gray-700">
        <span className="flex items-center space-x-2">
          <HomeIcon className="w-6 h-6" />{" "}
          <span className="text-lg font-semibold text-yellow-400">
            Real Estate
          </span>
        </span>
        <ul className="hidden space-x-4 text-sm font-light md:flex">
          <li className="cursor-pointer hover:text-yellow-400">Buy</li>
          <li className="cursor-pointer hover:text-yellow-400">Sell</li>
          <li className="cursor-pointer hover:text-yellow-400">Rent</li>
          <li className="cursor-pointer hover:text-yellow-400">
            Property Management
          </li>
          <li className="cursor-pointer hover:text-yellow-400">Commercial</li>
          <li className="cursor-pointer hover:text-yellow-400">
            Body Corporate
          </li>
          <li className="cursor-pointer hover:text-yellow-400">Projects</li>
          <li className="cursor-pointer hover:text-yellow-400">Find us</li>
        </ul>
      </div>
      <div
        className="flex flex-col min-h-screen bg-fixed bg-right bg-no-repeat bg-cover md:flex-row"
        id="main"
        style={{
          backgroundImage: `url(
            "/img/background/home.jpg")`
        }}
      >
        <div className="flex items-center justify-center flex-1 px-2 pt-20 pb-2 bg-gray-200 finance md:px-5 md:pb-5">
          <div className="w-full max-w-md p-5 space-y-8">
            <Heading page={page} />
            <div className="w-full p-2 space-y-4 rounded-md md:p-5">
              <div className="flex flex-col items-center justify-center space-y-4">
                <div className="flex items-center justify-center p-6 bg-gray-700 rounded-full h-28 w-28">
                  <HomeIcon className="w-16 h-16 text-yellow-400" />
                </div>
              </div>
              {submissionId ? (
                <Formatic
                  {...formProps}
                  data={data}
                  formId={581}
                  apiKey="afdcDf159365F9d9A6d12E334be147562A97F2B2b3a46D6A15F0cF611240Ab63"
                  environment="Staging"
                  channel="master"
                  initialValues={{}}
                  config="default"
                  theme="transformd"
                  submissionId={submissionId}
                >
                  <Overrides.OverrideFieldContainer
                    component={RelationshipCustom}
                    type="personRelationship"
                  />
                  <Overrides.OverrideFieldContainer
                    component={CustomCheckboxList}
                    type="checkboxList"
                  />
                  <Overrides.OverrideFieldContainer
                    component={CustomRadioList}
                    type="radioList"
                  />
                  <Overrides.OverrideFieldContainer
                    component={CompanySearchAutocomplete}
                    type="companySearch"
                  />
                  <Overrides.OverrideFieldContainer
                    type="text"
                    id="62d4b4e0215a3c049d39a133"
                    component={() => <span />}
                  />
                </Formatic>
              ) : (
                <div>Loading form and values...</div>
              )}
            </div>
          </div>
        </div>
        <div className="flex h-full md:min-h-screen md:flex-1"></div>
      </div>
    </div>
  );
};

export default RealEstatePage;
